import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { colors, TableCell, TableRow, Typography } from '@material-ui/core'
import { Label, FleetIcon } from 'frame/components'
import { getDateColor } from 'listable'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'


const useStyles = makeStyles(theme => ({
  root: {
    verticalAlign: 'text-top',
  },
  label: theme.typography.bodySmall,
  clickable: {},
  spacer: {
    margin: 0,
    padding: 0,
  },
}))

const getDaysToDelivery = (date) => {
  if ( ! date) {
    return ''
  }
  const a = moment(date)
  const b = moment(new Date())
  return a.diff(b, 'days')
}

const getDayColor = (date) => {
  if (moment(date).isBefore(new Date())) {
    return colors.red[600]
  }
  return colors.grey[600]
}

const Row = ({ account, item, columns }) => {

  const classes = useStyles()
  // const isPremium = account === 'premium'

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')  

  return (
    <TableRow
      hover
      className={classes.root}>
      <TableCell className={classes.spacer} />
      {columns.accountName && (
        <TableCell className={classes.label}>
          <FleetIcon fleetName={item.accountName} />
        </TableCell>
      )}
      {columns.quoteBundle && (
        <TableCell className={classes.label}>
          {item.quoteBundle || '-'}
        </TableCell>
      )}
      {columns.agreementNo && (
        <TableCell className={classes.label}>
          {item.agreementNo || '-'}
        </TableCell>
      )}
      {columns.previousAgreement && (
        <TableCell className={classes.label}>
          {item.previousAgreement || '-'}
        </TableCell>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCell className={classes.label}>
          <Typography color="inherit" variant="body2">
            {item.driverNote || '-'}
          </Typography>
        </TableCell>
      )}      
      {columns.driver && (
        <TableCell className={classes.label}>
          <Typography
            color="inherit"
            variant="body2">
            {item.driver || '-'}
          </Typography>
        </TableCell>
      )}
      {columns.costCentre && (
        <TableCell className={classes.label}>
          {item.costCentre || '-'}
        </TableCell>
      )}
      {columns.year && (
        <TableCell className={classes.label}>
          {item.year || '-'}
        </TableCell>
      )}
      {columns.make && (
        <TableCell className={classes.label}>
          {item.make || '-'}
        </TableCell>
      )}
      {columns.model && (
        <TableCell className={classes.label}>
          {item.model || '-'}
        </TableCell>
      )}
      {columns.variant && (
        <TableCell className={classes.label}>
          {item.variant || '-'}
        </TableCell>
      )}
      {columns.fuelType && (
        <TableCell className={classes.label}>
          {item.fuelType || '-'}
        </TableCell>
      )}
      {columns.poRaisedDate && (
        <TableCell className={classes.label}>
          {item.poRaisedDate ? moment(item.poRaisedDate).format('DD/MM/YY') : '-'}
        </TableCell>
      )}
      {columns.requestedDeliveryDate && (
        <TableCell className={classes.label}>
          {item.requestedDeliveryDate ? (
            <Label
              color={getDateColor(item.requestedDeliveryDate)}
              variant="outlined">
              {moment(item.requestedDeliveryDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.expectedDeliveryDate && (
        <TableCell className={classes.label}>
          {item.expectedDeliveryDate ? (
            <Label
              color={getDateColor(item.expectedDeliveryDate)}
              variant="outlined">
              {moment(item.expectedDeliveryDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.previousRegistration && (
        <TableCell className={classes.label}>
          {item.previousRegistration || '-'}
        </TableCell>
      )}    
      {columns.days && (
        <TableCell className={classes.label}>
          {item.expectedDeliveryDate ? (
            <Label
              color={getDayColor(item.expectedDeliveryDate)}
              variant="outlined">
              {getDaysToDelivery(item.expectedDeliveryDate)}
            </Label>
          ) : '-'}
        </TableCell>
      )}
      {columns.location && (
        <TableCell className={classes.label}>
          {item.location || '-'}
        </TableCell>
      )}
      {/* {(isPremium && columns.sslvNumber) && (
        <TableCell className={classes.label}>
          {item.sslvNumber || '-'}
        </TableCell>
      )} */}
      <TableCell className={classes.spacer} />
    </TableRow>
  )
}

Row.propTypes = {
  account: PropTypes.string,
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
